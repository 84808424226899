$block: '.logo-ribbon';

#{$block}  {
  @include flexbox-horizontal;
  // flex-wrap: wrap;
  margin-bottom: 0;

  &:before {
    flex-basis: 0; /* safari bugfix */
  }

  img {
    max-height: 40px;
    max-width: 65px;
    margin-right: 15px;
  }

  &__item {
    // @include flexbox-horizontal;
    justify-content: center;
    align-items: center;
    // flex: 1 1 15%;
    // margin-bottom: 50px;
    //padding: 0 2vw;
    @media (max-width: $screen-xs-max) {
      flex: 1 1 30%;
      &:nth-of-type(-n+3) {
        margin-bottom: 30px;
      }
    }

    img {
      max-height: 40px;
      max-width: 65px;
      //margin-right: 30px;
      width: 100%;
    }
  }
}

#{$block}--pd {
  margin: 2vh 0;
  #{$block}__item {
    justify-content: flex-start;
    padding: 0;
  }
}
