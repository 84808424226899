/*****************/
/* MAIN SETTINGS */
/*****************/

html { font-size:13px; }
body * { box-sizing: border-box; @include smooth-text(); }
html, body { height:100%; margin:0; padding:0; }
body, h1, h2, h3, h4, h5, h6 { line-height: 1; font-size:1rem; @include source(); }
.googlemaps { max-width: 100%; min-width: 100%; height: calc(100vh - 330px);
  img { max-width: none; }
}
.clickable { cursor: pointer; }
p:first-child { margin-top:0; }
::selection { background: $clr-selection; color:#fff; }
::-moz-selection { background: $clr-selection; color:#fff; }
.placeholder .form-label { display: none; }
textarea { resize:none; }
svg, img[src$=".svg"], iframe, object, video { max-width: 100%; }
.svg-icon { max-width: 100%; max-height: 100%; fill: currentColor; display: inline-block; }
[data-sr] { visibility: hidden; }
*:active, *:focus { outline:none; box-shadow: none; }
a:not([href]) { cursor: default; }
img { min-height: 1px; }


/***********/
/* HELPERS */
/***********/

.fill                   { width: 100%; height:100%; }
.overlay                { position: absolute; top:0; left:0; }
.text-center            { text-align: center; }
.text-left              { text-align: left; }
.text-right             { text-align: right; }
.text-justify           { text-align: justify; text-align-last: center; }
.clear                  { clear:both; }
.vgutters               { > * { margin-bottom: $grid-gutter-width; } }
.two-cols {
  column-count: 2;
  column-gap: $grid-gutter-width;
  text-align: justify;
}

:not(html).flexbox      { @include flexbox-horizontal; }
.flexwrap               { flex-wrap: wrap; }
.flexrow                {
  @media (min-width: $screen-sm-min) {
    @include flexbox-horizontal; flex-wrap: wrap;
    > * { @include flexbox-vertical; }
    &:before { flex-basis:0; width:0; /* Safari bugfix */ }
    &:after {flex-basis:0; width: 0;}
    &--nowrap { flex-wrap: nowrap; }
  }
}
.valign                 { @include flexbox-vertical; justify-content: center; }
.halign                 { @include flexbox-horizontal; justify-content: center; }
.cvalign                { @extend .valign; align-items: center; }
.chalign                { @extend .halign; align-items: center; }

@media (max-width: $screen-xs-max) {
  .col-xs-spacing         { margin-bottom: 15vw; }
}

.sticky {
  @media (min-width: 767px) {
    &.sticky-on {
      position: fixed;
    }
  }
}

.switch-places {
  >*:first-child {
    order: 2;
  }
  >*:last-child {
    order: 1;
  }
}

// .middle {
//   display: inline-flex;
//   justify-content: center;
// }

.site-content {
  margin-top: 75px;
}

/*****************/
/* STYLE CLASSES */
/*****************/

.clr-white      { color:#fff; }
.clr-primary    { color:$brand-primary; }
.clr-secondary  { color:$brand-secondary; }
.clr-dark       { color:$clr-dark; }
.clr-iron       { color:$clr-iron; }

.bg-primary     { background-color:$brand-primary; }
.bg-secondary   { background-color:$brand-secondary; }
.bg-silver      { background-color:$clr-silver; }
.bg-dark        { background-color:$clr-dark; }
.bg-iron        { background-color:$clr-iron; }


/*****************/
/* STICKY FOOTER */
/*****************/

#site-wrap {
  min-height: 100%;
  @include flexbox-vertical;
}
#site-content {
  flex-grow: 1;
}


/******************/
/* MANEUVER BADGE */
/******************/

#mnvr { position:absolute; bottom:12px; left:12px;
  .inner { display:block; width:13px; height:6px; margin:auto; background:url('#{$imgpath}mnvr-light.png') no-repeat 0 0;  opacity:.3; transition:all 250ms ease-in-out;
    &:hover { width:63px; opacity:.3; }
  }
}
