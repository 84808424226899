//divider.scss

.divider {
  text-align: center;

  &__title {
    color: #fff;
    margin-bottom: 2vh;
    line-height: 2.9rem;
  }

  &__text,
  &__quote {
    @include source();
    color: #fff;
  }

  &__quote {
    // &:before {
    //   content: url('#{$imgpath}quote.svg');
    //   display: block;
    //   width: 20px;
    // }
  }

  &__author {
    @include weight(light);
    color: #fff;
  }
}

.stamp--red {
  fill: $brand-primary;
}

.divider-double {
  height:18.7px;
  background-image: url('#{$imgpath}/stamp--rg.svg');
  background-repeat: repeat-x;

  .no-smil & {
    background-image: url('#{$imgpath}/stamp--rg.png');
  }
}
