.main-menu {

  // Basic styling.
  .nav {
    margin-right: 70px;
    @media (max-width: $nav-more-breakpoint) {
      margin-right: 80px;
    }

    .menu-link {
      line-height: 20px;
      display: block;
      position: relative;
      color: $clr-iron;

      &:hover,
      &:focus,
      &.active,
      &.current-menu-item {
        background-color: transparent;
        text-decoration: none;
        color: $brand-primary;
      }

      &.active,
      &.current-menu-item {
        text-transform: uppercase;
        @include weight(semi);
      }
    }
  }

  .sub-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    left:0;
    top:100%;
    min-width: 68px;
  }


  // On mobile screens:
  // Center everyting on the page.
  @media (max-width: $grid-float-breakpoint) {
    .nav {
      @include flexbox-vertical;
      align-items: center;
      text-align: center;

      >li>a, .menu-link {
        padding: 30px 16px;
        min-width: 40vw;
      }
    }
  }

  // On larger screens:
  // Reset to the default navbar.
  @media (min-width: $grid-float-breakpoint) {

    .sub-menu {
      position: absolute;
      padding:10px;
      background-color: $clr-silver;
      opacity: 0;
      pointer-events: none;
      max-height: 0;
    }

    li:hover, li:active, li:focus {
      > .sub-menu {
        opacity: 1;
        pointer-events: all;
        max-height: 500px;
      }
    }
  }
}

.nav-lang-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0 48px 0 0;
  z-index: 2;
  height: 100%;
  width: 43.88px;
}
.nav-lang-current {
  position: absolute;
  z-index: 10;
  color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $brand-primary;
  @include weight(semi);
}

.nav-lang {
  position: absolute;
  top:76px;
  z-index: 9;
  background-color: $brand-primary;
  margin: 0;
  height: auto;
  transition: transform 400ms $easeOutExpo, top 400ms $easeInOutCubic;
  width: 43.88px;
  transform: translateY(-100%);

  .site-header.shrink & {
    top: 50px;
  }

  .nav-lang-wrapper:hover & {
    transform: translateY(0);
  }

  >li>a {
    color: white;
    @include weight(semi);
    top: 50%;
    // visibility: hidden;
    z-index: 1;
    background-color: $brand-primary;

    &:hover {
      background-color: darken($brand-primary, 5%);
    }
  }

  &:after {
    content: " ";
    display: block;
    position: absolute;
    left:0;
    bottom: -9px;
    width: 100%;
    height: 15px;
    background-image: url('#{$imgpath}stamp--red.svg');
    background-repeat: no-repeat;
    background-position: 0 0;
    transform: rotate(180deg);
    z-index: 2;
  }

  .no-smil &:after {
    background-image: url('#{$imgpath}stamp--red.png');
  }
}


html body .site-wrap .nav-more {
// We add 'body .site-wrap' just to override bootstrap's retarded specifity
// without having to use !important.

  &-link {
    display: none;
  }
  &-list {
    left: auto !important;
    right:0;
  }

  @media (min-width: $grid-float-breakpoint) and (max-width: ($nav-more-breakpoint - 1)) {
    &-link {
      display: block;
    }
    &-list {
      text-align: right;
    }
  }

  @media (min-width: max($nav-more-breakpoint, $grid-float-breakpoint)) {
    &-item {
      margin-left: 0;
      margin-right: 0;
    }
    &-list {
      @include clearfix;
      position: static;
      display: block;
      opacity: 1;
      max-height: none;
      background-color: transparent;
      padding: 0;

      li {
        float:left;
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }
}

@media (max-width: $grid-float-breakpoint) {
  body .main-menu {
    height: 85vh;
    max-height: none !important;
    //width: 100vw;
    font-size: 2rem;

    .nav {
      margin: 37px 0;
    }

    li {
      border-bottom: 1px solid $brand-primary;
      //padding: 30px 0;

      &:last-of-type {
        border-bottom: none;
      }
    }

    .nav-lang--mb {
      li {
        display: inline-block;
        border-bottom: none;
        border-right: 1px solid $brand-secondary;
        padding: 0;

        &:last-of-type {
          border-right: none;
        }

        a {
          min-width: auto;
          font-size: 1rem;
          margin: 0;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }

  .navbar-brand {
    width: 110px;
  }
}
