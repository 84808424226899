// parts.scss

.contact {

  &__title {
    margin-top: 4vh;

    @media (min-width: 768px) {
      margin-top: 0;
    }
  }

  &__address {
    font-style: normal;
    line-height: 1.8rem;
    a {
      color: $clr-dark;
    }
  }

}

.product-detail__infolist {
  padding-left: 0;

  li {
    list-style-type: none;
  }
}
