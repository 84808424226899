
// .btn-secondary {
//   @include button-variant(#fff, $brand-secondary, darken($brand-secondary, 4%));
// }
//
// .btn-ghost {
//   &.btn-default {
//     @include button-variant($btn-default-color, transparent, $btn-default-border);
//     &:hover {
//       @include button-variant($btn-default-color, #eee, $btn-default-border);
//     }
//   }
//   &.btn-primary {
//     @include button-variant($brand-primary, transparent, $brand-primary);
//     &:hover {
//       color: #fff;
//       background-color: $brand-primary;
//     }
//   }
//   &.btn-secondary {
//     @include button-variant($brand-secondary, transparent, $brand-secondary);
//     &:hover {
//       color: #fff;
//       background-color: $brand-secondary;
//     }
//   }
// }
.btn {
  background-color: transparent;
  border-radius: 0;
  border: none;
  text-align: left;
  // max-width: 150px;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    background-color: transparent;
    border-radius: 0;
    outline: none;
    box-shadow: none;
  }
}
.btn-primary {
  color: $brand-primary;
  text-transform: lowercase;
  @include weight(semi);
  // border-bottom: 2px solid transparent;
  transition: border-bottom 500ms, color 500ms;
  padding-left: 0;
  padding-right: 0;

  &:hover,
  &:focus,
  &:active,
  &:active:focus {
    color: $brand-secondary;
    @include weight(semi);
    // border-bottom: 2px solid $brand-secondary;
  }

  &--grey {
    color: $clr-iron;
  }
}

.btn-default {
  color: $brand-primary;
  text-transform: lowercase;
  padding-left: 0;
  padding-right: 0;
  @include weight(semi);
  transition: color 500ms;
  text-align: center;

  &:after {
    content: "\61";
    display: block;
    font-family: "pluvera";
    text-transform: none;
    speak: none;
    line-height: inherit;
    font-size: 90%;
    position: relative;
    color: currentColor;
  }

  &:hover,
  &:focus {
    color: $brand-secondary;
  }
}

.btn-secondary {
    color: $brand-primary;
    padding-left: 0;
    padding-right: 0;
    @include arkiv();
    font-size: 1.2rem;
    transition: color 500ms;

    &:hover,
    &:focus {
      color: $brand-secondary;
    }
}

.btn-arrow {
  &:after {
    content: "\61";
    display: inline-block;
    font-family: "pluvera";
    text-transform: none;
    speak: none;
    line-height: inherit;
    font-size: 100%;
    position: relative;
    color: currentColor;
    margin-left: .75rem;
    transform-origin: center 13px;
    transition: transform 400ms $easeInOutCubic;
  }

  &.open:after {
    transform: rotate(180deg);
  }
}

.btn-download {
  display: block;
  position: relative;
  //max-width: 245px;
  color: $clr-dark;
  white-space: normal;
  padding-left: 0;
  padding-right: .5rem;
  font-size: 1.6rem;
  border-bottom: 1px solid rgba($clr-dark, .5);
  transition: color 500ms, border-bottom 500ms;

  &:after {
    content: "\62";
    display: inline-block;
    font-family: "pluvera";
    text-transform: none;
    speak: none;
    line-height: inherit;
    font-size: 100%;
    position: absolute;
    color: rgba($clr-dark, .5);
    margin-right: 1rem;
    top: 3px;
    right: 0;
    //transition: color 250ms;
  }

  &:hover,
  &:focus {
    color: #000;
    border-bottom-color: currentColor;

    &:after {
      color: currentColor;
    }
  }
}
