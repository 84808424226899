// .large-title {
//   font-size: 2rem;
//   font-weight: 700;
//   margin:0 0 1.5em 0;
// }
// .small-title {
//   font-size: 1.5rem;
// }
// .item-title {
//   text-transform: uppercase;
//   font-weight: 700;
//   font-size:1.1rem;
// }


.hero-title,
.section-title,
.item-title {
  @include arkiv();
}

.hero-title {
  color: #fff;
  text-transform: uppercase;
  font-size: 3.2rem;
}

.section-title {
  color: $brand-secondary;
  font-size: 2.3rem;
  margin-top: 0;
  padding: 0;

  &--small {
    font-size: 1.3rem;
  }
  &--medium {
    font-size: 2rem;
  }

  &--red {
    color: $brand-primary;
  }

  &--black {
    color: #000;
  }
}

.item-title {
  color: $brand-primary;
  font-size: 2rem;

  &:after {
    content: "";
    display: block;
    width: 35px;
    height: 2px;
    background-color: currentColor;
    margin-top: 10px;
  }

  &--small {
    font-size: 1rem;

    &:after {
      width: 20px;
      margin-top: 5px;
    }
  }
}
