@mixin weight($weight: 'normal') {
  $weights: (
  light: 300,
  normal: 400,
  semi: 600
  );

  font-weight: map-get($weights, $weight);
}

@mixin source($weight: 'normal') {
  font-family: 'Source Sans Pro', sans-serif;
  @include weight($weight);
}

@mixin arkiv($weight: 'normal'){
  font-family: 'arkiv-bold', sans-serif;
  @include weight($weight);
}

@font-face {
  font-family: "arkiv-bold";
  src:url("#{$fontpath}/arkiv-bold.eot"),
  //src:url("#{$fontpath}/arkiv-bold.eot?#iefix") format("embedded-opentype"),
    url("#{$fontpath}/arkiv-bold.woff") format("woff"),
    url("#{$fontpath}/arkiv-bold.ttf") format("truetype"),
    url("#{$fontpath}/arkiv-bold.svg#arkivbold") format("svg");
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: "pluvera";
  src:url("#{$fontpath}/pluvera.eot");
  src:url("#{$fontpath}/pluvera.eot?#iefix") format("embedded-opentype"),
    url("#{$fontpath}/pluvera.woff") format("woff"),
    url("#{$fontpath}/pluvera.ttf") format("truetype"),
    url("#{$fontpath}/pluvera.svg#pluvera") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "pluvera" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "pluvera" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sort-desc:before {
  content: "\61";
}
.icon-download-pdf:before {
  content: "\62";
}
.icon-caret-right:before {
  content: "\63";
}
