.feature {
  text-align: center;
  padding: 0 30px;
  align-items: center;
  flex: 33.33%;
  border-right: 1px solid $clr-silver;

  &:last-of-type {
    border-right: none;
  }

  &__title {

  }

  &__text {
    @media (min-width: $screen-sm-min) {
      max-width: 80%;
    }
    flex: 1 0 auto;
  }

  &__actions {

  }
}
