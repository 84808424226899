// production.scss

$block: '.tabbed';

#{$block} {
  flex-wrap: wrap;

  @media (min-width: $screen-sm-min) {
    display: flex;
  }

  &>*:not(#{$block}__switch) {
    flex-basis: 25%;
  }

  &:after {
    display: block;
    content: "";
    clear: both;
  }

  &__switch {
    position: fixed;

    &:target {
      + #{$block}__toggle__icon {
        svg {
          .icon-number-bg,
          .icon-draw__outline {
            fill: $brand-primary;
          }
        }

        #{$block}__toggle__title {
          color: $brand-primary;
        }

        + #{$block}__content {
          display: block;
        }

      }
    }
  }

  &__toggle {
    &__icon {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      svg {
        max-width: 165px;
        width: 150px;
      }

      &:hover {
        text-decoration: none;
        svg {
          .icon-number-bg,
          .icon-draw__outline {
            fill: $brand-primary;
          }
        }

        #{$block}__toggle__title {
          color: $brand-primary;
        }
      }

      @media (min-width: $screen-sm-min) {

        &:after {
          content: "";
          display: block;
          height: 2px;
          width: 45px;
          border-bottom: 1px dotted gray;
          position: absolute;
          top: 50%;
          left: -23px;
          right: 0;
          margin: 0;
        }

        &:first-of-type:after {
          content: none;
        }
      }
    }

    &__title {
      @include arkiv();
      color: $clr-dark;
      margin-top: 20px;
      font-size: 1.2rem;
    }
  }


  &__content {

    @media (min-width: $screen-sm-min) {
      display: none;
      padding: 50px 250px;
    }
    flex-basis: 100% !important;
    text-align: center;
    padding: 50px 10vw;
    // animation: mymove 500ms ease-in-out;
    animation: scale 800ms ease-in-out;
  }
}
// @keyframes mymove {
//     from   {
//       transform: translateY(500px);
//       opacity: 0;
//     }
//     to {
//       transform: translateY(0);
//       opacity: 1;
//     }
// }

@keyframes scale {
  0% {
    transform: scale(0.9);
    opacity: 0;
    }
  50% {
    transform: scale(1.01);
    opacity: 0.5;
    }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

////////////SVG Styles//////////////////
#{$block}__toggle__icon {
  svg {
    .icon-number-bg {
      fill: #bababa;
    }

    .icon-draw {
      &__outline {
        fill: $clr-dark;
      }
    }
  }
}
//////////////////////////////////////////

// @media $grid-float-breakpoint {
//
// }
