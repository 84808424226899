.gallery {
  // display: none;
  &__item {
    width: 300px;
    height: 200px;
    margin:0 auto;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

    @media (min-width: $screen-sm-min) {
      width: 600px;
      height: 400px;
    }
  }

  .slick-dots {
    bottom: -30px;

    li button:before {
      color: #ccc;
    }
    li.slick-active button:before {
      color: $brand-primary;
    }
  }
}
