.site-footer {
  background-color: $clr-dark;
  padding: 20px;
  position: relative;

  .rights {
    color: $clr-silver;
    font-size: .8rem;
  }
}
