// Core variables and mixins
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Reset and dependencies
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/print";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/glyphicons";

// Core CSS
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/type";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/code";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/buttons";

// Components
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/dropdowns";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/input-groups";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/navs";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/navbar";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/breadcrumbs";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pagination";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/pager";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/labels";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/badges";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/jumbotron";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/thumbnails";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/alerts";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/progress-bars";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/media";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/list-group";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/panels";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/wells";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/close";

// Components w/ JavaScript
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/modals";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/tooltip";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/popovers";
// @import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/carousel";

// Utility classes
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";

/* Create extra grid classes for the smallest screens. */
@media (max-width: $screen-xxs-max) {
  @include make-grid(xxs);
}

/***********************/
/* BOOTSTRAP OVERRIDES */
/***********************/

.jumbotron, .navbar { margin-bottom: 0; }
.navbar-toggle { border: none;
  .icon-bar { background-color: $clr-iron; }
}
.navbar-nav {
  &.navbar-links {
    padding: 10px 0;
    @media (min-width: $screen-sm-min) {
      padding:15px 0;
    }

    >li {
      margin-left: 15px;
      margin-right: 15px;

      >a {
        padding:0;
      }
    }

    .menu-link {
      padding: 0;
    }
  }
}

@media (max-width: $grid-float-breakpoint) {
  .hidden-xxs { display: none !important; }
  .visible-xxs { display: block !important; }
}
