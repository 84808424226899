.shortcuts {
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  display: none;

  @media (min-width: $screen-sm-min) {
    display: block;
  }

  &__container {
    list-style: none;
    padding: 4px 8px;
    margin: 0;
    background: #fff;
    box-shadow: 0 0 5px rgba(0,0,0,.2);
    border-radius: 21px;

  }

  &__item {
    margin: 8px 0;

    &.active {
      .shortcuts__bullet {
        background-color: $brand-secondary;
      }
    }
  }

  &__link {
    text-decoration: none !important;

    &:hover {

      .shortcuts__bullet {
        background-color: darken($brand-primary, 20%);
      }
      .shortcuts__label {
        opacity: 1;
        color: $brand-primary;
        transform: translateX(0);
      }
    }
  }

  &__label {
    margin-right: 10px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    display: block;
    right: 26px;
    padding: 2px 8px;
    border-radius: 8px;
    text-align: right;
    font-weight: bold;
    transform: translateX(10px);
    transition: all 250ms $easeInOutCubic;
    white-space: nowrap;
    background: #fff;
  }

  &__bullet {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: $brand-primary;
    border-radius: 50%;
  }
}
