// milieu.scss

.milieu {
  &__text {
    // line-height: 1.5rem;
    display: block;
    text-align: center;
    margin-top:10px;

    @media (min-width: $screen-sm-min) {
      border-left: 1px solid $clr-iron;
      text-align: left;
      margin-top: 0;
    }

    &--right {
      text-align: center;
      border-left: none;
      @media (min-width: $screen-sm-min) {
        text-align: right;
        border-right: 1px solid $clr-iron;
      }
    }

    &--green {
      color: $brand-secondary;
      display: inline;
    }
  }

  &__img {
    max-height: 77px;
  }
}
