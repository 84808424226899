.hero {
  height: auto;
  padding:50px 0;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @include flexbox-vertical;
  justify-content: center;

  @media (min-width: 980px) {
    padding:0;
    height: 45vw;
    max-height: 65vh;
  }

  .front & {
    &:after {
      content: "";
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: rgba(0,0,0,0.3);
      top: 0;
      left: 0;
    }
  }

  &--center {
    align-items: center;

    &__content {
      position: relative;
      z-index: 2;
      color: #fff;
      max-width: 65%;
      font-size: 3rem;
      line-height: 3.7rem;
      text-transform: uppercase;
      text-align: center;
      @include arkiv();

      // &:after {
      //   content: url("#{$imgpath}/line.svg");
      //   display: block;
      //   width: 30%;
      //   max-width: 300px;
      //   margin: 0 auto;
      // }
    }

    &__content--slider{
        max-width: none;
        // margin-top: 10%;
        height: 100%;
    }

    &:after {
      content: "";
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: rgba(0,0,0,0.3);
      top: 0;
      left: 0;
    }
  }

  &--left {
    // padding-left: 10vw;

    &__content {
      position: relative;
      z-index: 2;
      color: #fff;
      max-width: 100%;
      font-size: 1.5rem;
      line-height: 1.85rem;
      @include arkiv();


      font-weight: 100;
      letter-spacing: 1px;
      text-align: left;
      text-shadow: 0 0 10px rgba(0,0,0,0.3);

      &__first {
        margin-bottom: 20px;

        // &:after {
        //   content: url("#{$imgpath}/line.svg");
        //   display: block;
        //   width: 30%;
        //   //margin: 0 auto;
        // }
      }

      &__scnd {
        text-transform: uppercase;
        max-width: 55%;
      }

      @media (min-width: $grid-float-breakpoint) {
        font-size: 3rem;
        line-height: 3.7rem;
        max-width: 65%;
      }
    }
  }
}
