.page-section {
  padding:60px 0;

  /************/
  /* ELEMENTS */
  /************/

  &__header {
    text-align: center;
    margin-bottom: 40px;
  }

  &__title {
    font-size: 1.8rem;
    font-weight: bold;
    text-transform: uppercase;
    color: $brand-primary;
    margin-bottom: .75em;
  }

  &__subtitle {
    font-size: 1.3rem;
    font-weight: bold;
  }


  &__content {
    text-align: center;
  }

  &__content--left {
    text-align: left;
  }

  /*************/
  /* MODIFIERS */
  /*************/

  &--sm {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &--md {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  &--lg {
    padding-top: 140px;
    padding-bottom: 140px;
  }
  &--flat {
    padding: 0;
  }
  &--fullscreen {
    min-height: calc(100vh - #{$headerheight});
    @extend .valign;
  }
  &--bo {
    padding-top: 0;
  }
  &--red {
    background-color: $brand-primary;
  }
  &--reddiv {
    background-color: $brand-primary;

    > .container {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: " ";
      display: block;
      position: absolute;
      // bottom: 0;
      left:0;
      width: 100%;
      height: 20px;
      background-image: url('#{$imgpath}stamp--red.svg');
      background-repeat: repeat-x;
      background-position: 0 0;
      transform: translateY(250%);
      z-index: 1;
    }

    .no-smil &:after {
      background-image: url('#{$imgpath}stamp--red.png');
    }

    &--empty {
      padding: 0;
      &:after {
        transform: translateY(-50%);
      }
    }
  }
  &--redtop {
    background-color: $brand-primary;

    > .container {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: " ";
      display: block;
      position: absolute;
      // top: 0;
      left:0;
      width: 100%;
      height: 20px;
      background-image: url('#{$imgpath}stamp--rg.svg');
      background-repeat: repeat-x;
      background-position: 0 0;
      transform: translateY(-377%);
      z-index: 1;
    }

    .no-smil &:before {
      background-image: url('#{$imgpath}stamp--rg.png');
    }
  }
  &--whitetop {
    padding-bottom: 0;
    padding-top: 0;

    > .container {
      position: relative;
      z-index: 2;
    }

    &:before {
      content: " ";
      display: block;
      position: absolute;
      // top: 0;
      left:0;
      width: 100%;
      height: 20px;
      background-image: url('#{$imgpath}stamp--white.svg');
      background-repeat: repeat-x;
      background-position: 0 0;
      transform: translateY(0%);
      z-index: 1;
    }

    .no-smil &:before {
      background-image: url('#{$imgpath}stamp--white.png');
    }
  }
  &--silver {
    background-color: $clr-silver;
  }

}
