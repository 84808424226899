//packinfo.scss

.packinfo {
  width: 100%;


  td, th {
    padding: 8px;
    text-align: left;
  }

  &__title {
    color: $clr-iron;
    @include weight(semi);
  }

  &__cell {
    // padding-left: 0;
    border-bottom: 1px solid $clr-silver;

    &.has-img {

      &:hover, &.selected {
        background-color: $clr-silver;
      }
      // &:after {
      //   content: url('#{$imgpath}camera.svg');
      //   position: absolute;
      //   right: 20px;
      //   margin-top: 8px;
      // }
    }

    &:last-of-type {
      border-bottom: none;
    }

    td {
      color: $clr-iron;


      &.selected {
        @include weight(semi);
      }
    }
  }
}
