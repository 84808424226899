.site-header {
  background-color: $clr-silver;
  // position: relative;
  // padding-top: 20px;
  // padding-bottom: 20px;
  padding: 1em 1%;
  transition: padding 400ms $easeInOutCubic;

  .admin-bar & {
    top: 32px;
  }

  > .container {
    position: relative;
    z-index: 2;
  }

  .front & .navbar-brand img {
    @media (min-width: $screen-md-min) {
      width: 220px;
    }
    transition: width 400ms $easeInOutCubic;
  }
  .front &.shrink .navbar-brand img {
    width: 150px;
  }

  &:after {
    content: " ";
    display: block;
    position: absolute;
    bottom: 0;
    left:0;
    width: 100%;
    height: 20px;
    // background-color: red;
    background-image: url('#{$imgpath}stamp.svg');
    background-repeat: repeat-x;
    background-position: right 0;
    transform: translateY(50%);
    z-index: 1;
  }

  .no-smil &:after {
    background-image: url('#{$imgpath}stamp.png');
  }

  &.shrink {
    padding-top: 0;
    padding-bottom: 0;
    // box-shadow: 0 0 3px 0 rgba(0,0,0,.18);

  }

}

.site-header.navbar-fixed-top + .site-content {
  margin-top: $headerheight;
}
