
/* helpers */
@mixin reset          { margin:0; padding:0; border:none; background:none; list-style: none; line-height: 1; box-shadow: none; }
@mixin smooth-text    { -webkit-font-smoothing:antialiased; text-rendering: optimizeLegibility; -moz-osx-font-smoothing: grayscale; }
@mixin up             { text-transform: uppercase; }
@mixin clearfix       { zoom:1;
  &:before,
  &:after             { content:""; display:table; }
  &:after             { clear: both; }
}

/* borders mixins */
@mixin borders($t, $r, $b, $l) {
  border-top-width: $t;
  border-right-width: $r;
  border-bottom-width: $b;
  border-left-width: $l;
}

/***********/
/* FLEXBOX */
/***********/

@mixin flexbox              { display: flex; }
@mixin flexbox-vertical     { @include flexbox; flex-direction:column; }
@mixin flexbox-horizontal   { @include flexbox; flex-direction:row; }


/***********/
/* SPACERS */
/***********/

/*
 * Use:
 * .spacer-sm-40   => 40px spacing on small screens.
 * .spacer-md-3vh  => 3vh spacing on medium screens.
 */

@mixin spacer($h, $bp, $unit:'px') {
  $suffix: $h;
  @if $unit != 'px' {
    $suffix: #{$h}#{$unit};
  }
  .spacer-#{$bp}-#{$suffix} { height: #{$h}#{$unit}; }
}

@mixin make-spacers($step:20, $max:200, $bp:'xs', $unit:'px') {
  $i: 0;
  @while $i <= $max {
    @include spacer($i, $bp, $unit);
    $i: $i + $step;
  }
}

@media (max-width: $screen-xxs-max) {
  @include make-spacers(20, 200, 'xxs');
  @include make-spacers(1, 20, 'xxs', 'vh');
  @include make-spacers(1, 20, 'xxs', 'vw');
}

@media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
  @include make-spacers(20, 200, 'xs');
  @include make-spacers(1, 20, 'xs', 'vh');
  @include make-spacers(1, 20, 'xs', 'vw');
}

@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  @include make-spacers(20, 200, 'sm');
  @include make-spacers(1, 20, 'sm', 'vh');
  @include make-spacers(1, 20, 'sm', 'vw');
}

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  @include make-spacers(20, 200, 'md');
  @include make-spacers(1, 20, 'md', 'vh');
  @include make-spacers(1, 20, 'md', 'vw');
}

@media (min-width: $screen-lg-min) {
  @include make-spacers(20, 200, 'lg');
  @include make-spacers(1, 20, 'lg', 'vh');
  @include make-spacers(1, 20, 'lg', 'vw');
}

/**********/
/* RHYTHM */
/**********/

/**
 * Helper classes to create and maintain the vertical rhythm of the page.
 *
 * Use either as a single class on an element:
 * <div class="mb2"></div>
 * or
 * <h3 class="pt5"></div>
 *
 * Or use the 'rhythm-' class to set vertical spacing inside a entire block.
 * <div class="rhythm-mb3"></div>
 *
 **/

$rhythm: .5rem;
$min: 1;
$max: 20;

/*** PADDING ***/

@for $i from $min through $max {
  .pt#{$i} { padding-top: $rhythm * $i; }
  .rhythm-pt#{$i} > * { margin-top: $rhythm * $i; }
}

@for $i from $min through $max {
  .pb#{$i} { padding-bottom: $rhythm * $i; }
  .rhythm-pb#{$i} > * { margin-bottom: $rhythm * $i; }
}

/*** MARGIN ***/

@for $i from $min through $max {
  .mt#{$i} { margin-top: $rhythm * $i; }
  .rhythm-mt#{$i} > * { margin-top: $rhythm * $i; }
}

@for $i from $min through $max {
  .mb#{$i} { margin-bottom: $rhythm * $i; }
  .rhythm-mb#{$i} > * { margin-bottom: $rhythm * $i; }
}
