.bodytext {
  font-size: 1.2rem;
  line-height: 1.6;
  color: $clr-dark;

  &--fat {
    @include weight(semi);
  }

  &--grey {
    color: $clr-iron;
  }

  h2 {
    font-size: 2rem;
    color: $brand-primary;
  }
  h3 {
    font-size: 1.6rem;
    color: inherit;
  }
  h4 {
    font-size: 1.3rem;
    color: inherit;
  }

  a {
    color: $brand-secondary;

    &:hover {
      text-decoration: underline;
    }
  }

  ul {
    list-style-position: inside;
    padding-left: 0;
    @include weight(light);

    ul {
      margin-left: 15px;
    }
  }

  // Standard classes added by Wordpress wysiwyg.
  img.alignright { float: right; margin: 0 0 1em 1em; }
  img.alignleft { float: left; margin: 0 1em 1em 0; }
  img.aligncenter { display: block; margin-left: auto; margin-right: auto; }
  .alignright { float: right; }
  .alignleft { float: left; }
  .aligncenter { display: block; margin-left: auto; margin-right: auto; }


  /* MODIFIERS */
  &--small {
    line-height: 1.2;
    font-size: 1rem;
  }
}


.specialtext {
  @include arkiv();
  font-size: 1.1rem;
  line-height: 1.6rem;
  padding-left: 4vw;
  border-left: 1px solid $brand-primary;
}

.specialtextnl {
  @include arkiv();
  font-size: 1.1rem;
  line-height: 1.6rem;
}
