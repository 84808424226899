// product

.product__imgsmall {
  width: 150px;
  height: 150px;
  display: flex;
  cursor: pointer;
  margin: 5px;
  flex-direction: column;
  justify-content: center;

  img {
    max-height: 150px; // IE hack.
  }
}

.product__imglarge {
  max-height: 390px;
  margin: 0 auto;

  &--empty {
    width: 100%;
    height: 100%;
    background-color: $clr-silver;
  }
}

.product-item {
  img {
    max-height: 175px;
    margin: 0 auto;
  }
}

.product-info__link {
  position: relative;
  display: block;

  &:before {
    content: attr(data-type);
    color: #fff;
    background-color: rgba(0,0,0,.2);
    text-decoration: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    z-index: 10;
    @include weight(semi);
    width: 100%;
    height: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 500ms $linear;
  }

  &:hover {
    // background-color: $clr-iron;
    text-decoration: none;
    position: relative;

    img {
      // opacity: 0.4;
    }

    &:before {
      opacity: 1;
    }

  }
}
