// quality-list.scss

.quality-list {
  margin-bottom: 20px;
  &__number {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: $brand-primary;
    color: #fff;
    text-align: center;
    line-height: 20px;
    @include weight(semi);
    float: left;
  }

  &__title {
    color: $brand-primary;
    @include weight(semi);
    margin-left: 30px;
    margin-bottom: 10px;
    font-size: 1.4rem;
  }

  &__text {
    margin-left: 30px;
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}
