 //slider.scss

 $block: '.slider';

 #{$block} {
  min-height: 350px;

   &__img {
     background-size: cover;
     background-position: center center;
     background-repeat: no-repeat;
   }

   &__content {
     padding: 45px;
   }
 }

 .slick-dots {
   bottom: 25px;

   li button:before {
     font-size: 2.5rem;
     opacity: 1;
     color: #fff;
   }

   li.slick-active button:before {
     color: $brand-primary;
     opacity: 1;
   }
 }

 .slick-arrow {
   content: "";

   &.slick-prev {
     background: url('#{$imgpath}arrow-left.svg') no-repeat center center;
   }
   &.slick-next {
     background: url('#{$imgpath}arrow-right.svg') no-repeat center center;
   }

   &.slick-disabled {
     opacity: .2;
   }
 }
